<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span>Use Case / Analyze Pleadings</span>
      </div>

      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <div class="left-container">
          <section class="tabs">
            <h5 class="tabs-title">Analyze Pleadings</h5>
            <p class="tabs-description">
              Compare claims and defenses, summarize key points, and uncover
              inconsistencies for faster legal insights.
            </p>
          </section>

          <section class="query-section">
            <article class="case-file mb-4">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="tabs">
                  <h5 class="tabs-title">Claims Upload: Upload Claim</h5>
                  <p class="tabs-description">
                    Upload your claim document(s) here (e.g., complaint,
                    petition).
                  </p>
                </div>
                <div class="tabs" style="position: relative; align-items: end">
                  <h5 class="tabs-title">Select Jurisdiction</h5>
                  <button
                    class="country-button d-flex align-items-center"
                    type="button"
                    @click="toggleCountry"
                    :style="{ color: selectedCountry ? 'var(--primary)' : '' }"
                  >
                    <span
                      class="material-symbols-rounded icon-small me-1"
                      :style="{
                        color: selectedCountry ? 'var(--primary)' : '',
                      }"
                      >arrow_drop_down</span
                    >
                    <img
                      v-if="selectedCountry"
                      :src="countryImages[selectedCountry]"
                      alt="Country Flag"
                      class="country-flag"
                    />
                    {{ selectedCountry ? selectedCountry : "Country" }}
                  </button>
                  <div
                    v-if="isCountry"
                    class="country-option"
                    style="width: 115px; top: 100%"
                    v-clickaway="toggleCountry"
                  >
                    <ul style="margin: 0; padding: 0">
                      <li
                        v-for="(flag, countryCode) in countryImages"
                        :key="countryCode"
                        @click="selectCountry(countryCode)"
                        class="countryOption"
                      >
                        <img
                          :src="flag"
                          alt="Country Flag"
                          class="country-flag"
                        />
                        <span>{{ countryCode }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="card">
                  <p class="card-title" v-if="displayedClaimFiles.length == 0">
                    No files uploaded yet.
                  </p>
                  <span class="button-add" @click="showAttachModal('claim')"
                    >+ Add Files</span
                  >
                  <div
                    v-if="claimFiles.length > 0 && claimFiles[2].length > 0"
                    style="
                      background: var(--Neutral-Interface-Grey, #fafbfc);
                      width: 100%;
                      margin-top: 1rem;
                    "
                  >
                    <b-row
                      style="
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                      "
                    >
                      <b-col
                        v-for="(file, index) in displayedClaimFiles"
                        :key="index"
                        cols="4"
                        style="grid-column: span 1 / span 1; width: fit-content"
                        class="d-flex align-items-center mt-2 mb-2"
                      >
                        <div class="d-flex align-items-center ms-4">
                          <span
                            class="material-symbols-rounded badge-icon me-2"
                          >
                            {{
                              file.name.endsWith(".zip")
                                ? "folder"
                                : "description"
                            }}
                          </span>
                          <p class="badge-title" style="margin: 0">
                            {{ truncateFileName(file.name) }}
                          </p>
                        </div>
                        <div class="ms-auto d-flex align-items-center me-4">
                          <span class="badge-size mb-0 me-2"
                            >{{
                              (file.file.size / (1024 * 1024)).toFixed(2)
                            }}
                            MB</span
                          >
                          <template v-if="file.uploading">
                            <span>
                              <b-spinner
                                small
                                style="width: 1.15rem; height: 1.15rem"
                                variant="primary"
                                label="Uploading"
                              ></b-spinner>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              style="cursor: pointer"
                              class="material-symbols-rounded close-icon"
                              @click="removeClaimFile(index)"
                              >close</span
                            >
                          </template>
                        </div>
                      </b-col>
                    </b-row>

                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <button
                        v-if="claimFiles[2].length > maxFilesToShow"
                        type="button"
                        @click="toggleShowAll"
                        class="d-flex align-items-center m-3"
                        style="color: #1890ff"
                      >
                        <span class="material-symbols-rounded icon">
                          {{
                            showAllClaim
                              ? "keyboard_double_arrow_up"
                              : "keyboard_double_arrow_down"
                          }}
                        </span>
                        {{ showAllClaim ? "Show Less" : "Show More" }}
                      </button>
                    </div>
                  </div>
                </div>
                <b-modal id="claim" hide-footer hide-header size="lg">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h5>Upload Claim Document(s)</h5>
                    </div>
                    <div>
                      <span
                        class="material-symbols-rounded close-icon"
                        @click="hideAttachModal('claim')"
                        >close</span
                      >
                    </div>
                  </div>
                  <div>
                    <FileInput
                      style="flex-grow: 1"
                      :accept="['pdf', 'docx']"
                      @input-files="handleClaimFileInput"
                    />
                    <p class="mt-2" style="font-size: 11px; font-weight: 400">
                      Your files will be securely stored in NeXa, accessible
                      only to you and your workspace admins, and you can delete
                      them at any time.
                    </p>
                    <FileProcess
                      style="width: 100%; max-height: 200px; overflow-y: auto"
                      :accept="['pdf', 'docx']"
                      :files="claimFiles[0]"
                      @output-files="$set(claimFiles, 1, $event)"
                    />
                  </div>
                  <footer>
                    <div class="d-flex justify-content-end">
                      <div
                        class="upload cursor-pointer"
                        @click="uploadClaimFile"
                      >
                        <span class="material-symbols-rounded icon"
                          >upload</span
                        >
                        Upload
                      </div>
                    </div>
                  </footer>
                </b-modal>
              </div>
            </article>
            <article class="case-file">
              <div class="tabs">
                <h5 class="tabs-title">Defense File Upload: Upload Defense</h5>
                <p class="tabs-description">
                  Upload your defense document(s) here (e.g., answer,
                  counterclaim).
                </p>
              </div>
              <div class="content">
                <div class="card" @click="showAttachModal('defense')">
                  <p
                    class="card-title"
                    v-if="displayedDefenseFiles.length == 0"
                  >
                    No files uploaded yet.
                  </p>
                  <span class="button-add">+ Add Files</span>
                  <div
                    v-if="defenseFiles.length > 0 && defenseFiles[2].length > 0"
                    style="
                      background: var(--Neutral-Interface-Grey, #fafbfc);
                      width: 100%;
                      margin-top: 1rem;
                    "
                  >
                    <b-row
                      style="
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                      "
                    >
                      <b-col
                        v-for="(file, index) in displayedDefenseFiles"
                        :key="index"
                        cols="4"
                        style="grid-column: span 1 / span 1; width: fit-content"
                        class="d-flex align-items-center mt-2 mb-2"
                      >
                        <div class="d-flex align-items-center ms-4">
                          <span
                            class="material-symbols-rounded badge-icon me-2"
                          >
                            {{
                              file.name.endsWith(".zip")
                                ? "folder"
                                : "description"
                            }}
                          </span>
                          <p class="badge-title" style="margin: 0">
                            {{ truncateFileName(file.name) }}
                          </p>
                        </div>
                        <div class="ms-auto d-flex align-items-center me-4">
                          <h class="badge-size mb-0 me-2"
                            >{{
                              (file.file.size / (1024 * 1024)).toFixed(2)
                            }}
                            MB</h
                          >
                          <template v-if="file.uploading">
                            <span>
                              <b-spinner
                                small
                                style="width: 1.15rem; height: 1.15rem"
                                variant="primary"
                                label="Uploading"
                              ></b-spinner>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              style="cursor: pointer"
                              class="material-symbols-rounded close-icon"
                              @click="removeClaimFile(index)"
                              >close</span
                            >
                          </template>
                        </div>
                      </b-col>
                    </b-row>

                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <button
                        v-if="defenseFiles[2].length > maxFilesToShow"
                        type="button"
                        @click="toggleShowAll"
                        class="d-flex align-items-center m-3"
                        style="color: #1890ff"
                      >
                        <span class="material-symbols-rounded icon">
                          {{
                            showAllDefense
                              ? "keyboard_double_arrow_up"
                              : "keyboard_double_arrow_down"
                          }}
                        </span>
                        {{ showAllDefense ? "Show Less" : "Show More" }}
                      </button>
                    </div>
                  </div>
                </div>
                <b-modal id="defense" hide-footer hide-header size="lg">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h5>Upload Defense Document(s)</h5>
                    </div>
                    <div>
                      <span
                        class="material-symbols-rounded close-icon"
                        @click="hideAttachModal('defense')"
                        >close</span
                      >
                    </div>
                  </div>
                  <div>
                    <FileInput
                      style="flex-grow: 1"
                      :accept="['pdf', 'docx']"
                      @input-files="handleDefenseFileInput"
                    />
                    <p class="mt-2" style="font-size: 11px; font-weight: 400">
                      Your files will be securely stored in NeXa, accessible
                      only to you and your workspace admins, and you can delete
                      them at any time.
                    </p>
                    <FileProcess
                      style="width: 100%; max-height: 200px; overflow-y: auto"
                      :accept="['pdf', 'docx']"
                      :files="defenseFiles[0]"
                      @output-files="$set(defenseFiles, 1, $event)"
                    />
                  </div>
                  <footer>
                    <div class="d-flex justify-content-end">
                      <div
                        class="upload cursor-pointer"
                        @click="uploadDefenseFile"
                      >
                        <span class="material-symbols-rounded icon"
                          >upload</span
                        >
                        Upload
                      </div>
                    </div>
                  </footer>
                </b-modal>
              </div>
            </article>
          </section>

          <div class="footer-upload">
            <button
              :disabled="!isAllFieldValid || isSubmitting"
              style="display: flex; align-items: center; gap: 8px"
              :class="{
                'button-generate': isAllFieldValid,
                'button-generate-muted': !isAllFieldValid,
              }"
              @click="generateMemo"
            >
              Generate Insight
              <b-spinner
                v-if="isSubmitting"
                variant="primary"
                style="scale: 0.75"
                class="material-symbols-rounded"
              />
            </button>
          </div>
        </div>
      </div>
      <sidebar
        v-clickaway="toggleSidebar"
        v-if="sidebarCollapsed"
        @toggle-sidebar="toggleSidebar"
      ></sidebar>
    </div>
  </div>
</template>
<script>
import countryImages from "./../../../assets/country/index";
import sidebar from "@/Pages/Mode/sidebar.vue";
import FileInput from "@/components/input/FileInput.vue";
import FileProcess from "@/components/input/FileProcess.vue";
import { getWordCount } from "@/store/utils.js";
import usecase from "@/store/usecase.js";
import { mapState } from "vuex";
export default {
  components: {
    sidebar,
    FileInput,
    FileProcess,
  },
  data() {
    return {
      isSubmitting: false,
      countryImages,
      loading: true,
      maxFilesToShow: 9,
      showAllClaim: false,
      showAllDefense: false,
      sidebarCollapsed: false,
      lawbotVisible: false,
      selectedCountry: "",
      isCountry: false,
      legalQuery: "",
      claimFiles: [[], [], []],
      defenseFiles: [[], [], []],
      askQuestion: "",
      finding: "",
      jurisdiction: {},
    };
  },
  computed: {
    ...mapState({
      storeLegalQuery: (state) => state.legalQuery,
    }),
    displayedClaimFiles() {
      return this.showAllClaim
        ? this.claimFiles[2]
        : this.claimFiles[2].slice(0, this.maxFilesToShow);
    },

    displayedDefenseFiles() {
      return this.showAllDefense
        ? this.defenseFiles[2]
        : this.defenseFiles[2].slice(0, this.maxFilesToShow);
    },

    filteredCountryImages() {
      return Object.entries(this.countryImages)
        .filter(([countryCode]) => countryCode !== "USA")
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    },
    isAllFieldValid() {
      return this.displayedClaimFiles.length > 0 &&
        this.displayedDefenseFiles.length > 0 &&
        this.selectedCountry.trim() !== ""
        ? true
        : false;
    },
  },
  mounted() {
    this.askQuestion = this.storeLegalQuery;
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
  methods: {
    removeDefenseFile(index) {
      this.defenseFiles[2].splice(index, 1);
    },
    handleDefenseFileInput(files) {
      const existingFileNames = this.defenseFiles[2].map((file) => file.name);

      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      if (newFiles.length > 0) {
        this.defenseFiles[0].push(...newFiles);
      } else {
        this.$toast.info("All selected files are already uploaded.");
      }
    },
    uploadDefenseFile() {
      console.log("Before upload:", this.defenseFiles);

      this.$set(this.defenseFiles, 2, this.defenseFiles[1]);
      this.$set(this.defenseFiles, 0, []);
      this.$set(this.defenseFiles, 1, []);

      this.displayedDefenseFiles.forEach((file) => {
        this.$set(file, "uploading", true);
        const uploadTime = Math.min(
          10000,
          Math.max(1000, (file.file.size / (1024 * 1024)) * 1000)
        );

        setTimeout(() => {
          this.$set(file, "uploading", false);
        }, uploadTime + 1000);
      });
      this.hideAttachModal("defense");
    },

    removeClaimFile(index) {
      this.claimFiles[2].splice(index, 1);
    },
    handleClaimFileInput(files) {
      const existingFileNames = this.claimFiles[2].map((file) => file.name);

      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      if (newFiles.length > 0) {
        this.claimFiles[0].push(...newFiles);
      } else {
        this.$toast.info("All selected files are already uploaded.");
      }
    },

    truncateFileName(name) {
      return name.length > 17 ? name.substring(0, 19) + "..." : name;
    },

    uploadClaimFile() {
      console.log("Before upload:", this.claimFiles);

      this.$set(this.claimFiles, 2, this.claimFiles[1]);
      this.$set(this.claimFiles, 0, []);
      this.$set(this.claimFiles, 1, []);

      this.displayedClaimFiles.forEach((file) => {
        this.$set(file, "uploading", true);
        const uploadTime = Math.min(
          10000,
          Math.max(1000, (file.file.size / (1024 * 1024)) * 1000)
        );

        setTimeout(() => {
          this.$set(file, "uploading", false);
        }, uploadTime + 1000);
      });
      this.hideAttachModal("claim");
    },

    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    getWordCount(text) {
      return getWordCount(text);
    },
    getQuery(query) {
      this.askQuestion = query;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal(type) {
      this.$bvModal.show(type);
    },
    hideAttachModal(type) {
      this.$bvModal.hide(type);
    },

    getFile(file) {
      this.files = file;
    },
    getJurisdiction(jurisdiction) {
      const keys = Object.keys(jurisdiction);
      const key = keys[0];

      this.jurisdiction = {
        [this.countryMapping[key]]: jurisdiction[key],
      };
    },

    selectCountry(countryCode) {
      this.selectedCountry = countryCode;
      this.isCountry = false;
    },
    async generateMemo() {
      this.isSubmitting = true;
      try {
        const countryMapping = {
          AUS: "Australia",
          USA: "USA",
          CAN: "Canada",
          NZ: "New Zealand",
          SG: "Singapore",
          MY: "Malaysia",
          UK: "United Kingdom",
        };
        const jurisdiction = {};
        const country = countryMapping[this.selectedCountry];
        jurisdiction[country] = ["all"];

        const formData = new FormData();
        if (
          this.defenseFiles[2] &&
          this.defenseFiles[2].length < 0 &&
          this.claimFiles[2] &&
          this.claimFiles[2].length < 0
        ) {
          this.$toast.error("Please upload at least one file.");
          return;
        }
        this.claimFiles[2].forEach((file, index) => {
          formData.append(`question_doc[${index}][file]`, file.file);
          formData.append(`question_doc[${index}][pleading_type]`, "claim");
        });

        this.defenseFiles[2].forEach((file, index) => {
          formData.append(`question_doc[${index}][file]`, file.file);
          formData.append(`question_doc[${index}][pleading_type]`, "defence");
        });

        formData.append("language", "en");
        formData.append("location_id", 1);
        formData.append("type", "analyze_pleadings");
        formData.append("jurisdiction", JSON.stringify(jurisdiction));
        const response = await usecase.CreateUsecase(formData);
        if (response.data) {
          this.$toast.success("Successfully");
          this.$router.push({
            name: "AnalyzePleadingsResult",
            params: { id: response.data.data.id },
          });
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$toast.error("Failed to upload files or generate insights.");
      }
    },
  },
};
</script>

<style scoped>
.country-option {
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
  z-index: 2;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.countryOption {
  cursor: pointer;
  margin-bottom: 10px;
  list-style: none;
  span {
    color: #86888d;
    font-size: 14px;
  }
}

.attach-button,
.country-button {
  width: fit-content;
  padding: 6px;
  color: #86888d;
  background-color: #eeeeee;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  margin-bottom: 20px;
}

.attach-button:hover,
.country-button:hover {
  color: var(--primary);
  > span {
    color: var(--primary);
  }
}

.button-add {
  display: flex;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  border-radius: 0.25rem;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  cursor: pointer;
}
.card-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card {
  display: flex;
  width: 100%;
  padding: 2.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.5rem;
  background-color: #fafbfc;
  box-shadow: 0px 0px 4px 0px #f2f3f3;
}
.footer-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
}

.button-generate {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  background-color: var(--Primary-Blue, #0e4485);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.button-generate-muted {
  border-radius: 4px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  background: rgba(14, 68, 133, 0.1);
  margin-top: 32px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.upload-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  width: 100%;
}
.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 5px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
</style>

<style scoped>
.left-container {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .left-container {
    padding: 0 20px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.tabs-title {
  color: #0e4485;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.tabs-description {
  color: #86888d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.query-section {
  margin-top: 32px;
  font: 16px/28px Poppins, sans-serif;
}
.case-file {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font-weight: 500;
  justify-content: center;
}

.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}

.case-file-title {
  margin-bottom: 4px;
}

.content {
  color: #d1d2d5;
  font-weight: 400;
  position: relative;
}
.searchbar {
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

.entry {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.legal-input {
  background: none;
  width: 100%;
}

.line {
  border: 1px solid #000;
  height: 28px;
  align-self: flex-start;
}

.char-count {
  align-self: end;
  margin-top: 40px;
}
.upload-file {
  margin-top: 32px;
  flex-direction: column;
  justify-content: flex-start;
}

.upload-section {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font: 500 16px/28px Poppins, sans-serif;
}
.upload-title {
  margin-bottom: 8px;
}

.upload-description {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
}

.upload {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.upload-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d1d2d5;
  border-radius: 8px;
  background-color: #fafbfc;
  min-width: 240px;
  padding: 27px 80px;
}
.upload-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-image {
  width: 32px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}
.upload-caption {
  color: #0e4485;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.upload-choose-file {
  color: #0e4485;
}

.upload-file-format {
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.upload-instruction {
  background-color: #fafbfc;
  border-radius: 8px;
  min-width: 240px;
  padding: 58px 70px;
  box-shadow: 0px 0px 4px rgba(242, 243, 243);
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.storage-info {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
  margin-top: 8px;
}

.folder-creation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.toggle {
  align-items: center;
  display: flex;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background-color: #d1d2d5;
  position: relative;
}
.toggle-checkbox {
  opacity: 0;
  position: absolute;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: #0e4485;
}

.toggle-checkbox:checked + .toggle-switch::before {
  transform: translateX(20px);
}

.toggle-switch::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  transition: transform 0.3s;
  box-shadow: 0px 2px 4px rgba(39, 39, 39, 0.1);
}

.toggle-label {
  font: 400 14px/24px Poppins, sans-serif;
  color: #383a3e;
}
.generate-button {
  align-self: end;
  border-radius: 4px;
  background-color: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
  padding: 4px 16px;
  margin-top: 32px;
  font: 500 16px Poppins, sans-serif;
}
</style>
<style scoped>
.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5 {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.right-container {
  height: 182px;
  display: flex;
  padding: 0px 53px 0px 99px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  box-shadow: 0px 0px 4px 0px #f2f3f3;
  p {
    color: var(--Neutral-Dark-Grey, #86888d) !important;

    /* Small Paragraph */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.toggle {
  display: flex;
  width: 40px;
}
.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}
.swift:hover {
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  border-radius: 4px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.upload {
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover {
  background: var(--primary);
}
</style>
